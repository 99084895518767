<template>
  <div class="member-shop-invoice-settings">
    <PageTitle
      title="商城優惠折抵設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'MemberShopParameterSetting' })"
    />
    <MemberPointConfigBlock
      v-if="checkAction('admin.shopPoint.page')"
      v-loading="loading"
      title="會員商城點數設定"
      :configData="configData"
      source="MEMBER_STORE_ORDER"
      rewardType="POINT"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { GetMemberShopInvoiceConfig } from '@/api/memberShop'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberPointConfigBlock from '../../components/MemberPointConfigBlock.vue'
import { usePermissions } from '@/use/permissions'
import store from '@/store'

export default defineComponent({
  name: 'MemberShopPromoSetting',
  components: { PageTitle, MemberPointConfigBlock },
  setup (props) {
    const { checkAction } = usePermissions()
    const loading = ref(false)
    const shopId = computed(() => store.getters.shop)
    const configData = ref({})

    const getPaymentConfig = async () => {
      const [res, err] = await GetMemberShopInvoiceConfig({ shopId: shopId.value })
      if (err) window.$message.error(err)
      configData.value = res
    }

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      await getPaymentConfig()
      loading.value = false
    }

    onMounted(async () => {
      await refresh()
    })
    return { refresh, configData, loading, checkAction }
  },
})
</script>

<style scoped lang="postcss">

</style>
